import {
  computed,
  DestroyRef,
  Inject,
  Injectable,
  makeStateKey,
  PLATFORM_ID,
  StateKey,
  TransferState,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { RouterHistoryService } from '@service/router-history.service';
import { WindowDetectService } from '@service/window-detect.service';

import { filter, map, Observable, switchMap, takeUntil, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionRestorerService {
  // 因 legacy api response 時間偶爾會超過 500，所以增加一些
  readonly dueTime = computed(() => (this.windowService.isLegacyWebsite() ? 800 : 500));
  readonly documentObserver = new Observable<ResizeObserverEntry[]>(subscriber => {
    const resize = new ResizeObserver(entries => subscriber.next(entries));
    resize.observe(this.document.body);
    return (): void => resize.disconnect();
  });

  private readonly ssrIsRenderedKey: StateKey<boolean> = makeStateKey('ssrIsRendered');

  constructor(
    private transferState: TransferState,
    private routerHistoryService: RouterHistoryService,
    private windowService: WindowDetectService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  waitDomHeightReady(): Observable<number> {
    return this.routerHistoryService.restoreScrollTop.pipe(
      filter(() => isPlatformBrowser(this.platformId)),
      switchMap(top =>
        this.documentObserver.pipe(
          filter(d => d[0].target.scrollHeight >= top),
          map(() => top),
          takeUntil(timer(this.dueTime()))
        )
      )
    );
  }

  handleRestore(ref: DestroyRef): void {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(this.ssrIsRenderedKey, true);
      return;
    }
    this.waitDomHeightReady()
      .pipe(takeUntilDestroyed(ref))
      .subscribe((top: number) => {
        const ssrIsRendered = this.transferState.get(this.ssrIsRenderedKey, false);
        if (ssrIsRendered) {
          // 重新整理頁面, 不用執行 restore scroll.
          this.transferState.remove(this.ssrIsRenderedKey);
          return;
        }
        if (top) {
          window.scrollTo({ top });
        }
      });
  }
}
